import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Select, Space } from "antd";
import { AuthTokenType, DataProps, AddLeadFormProps, lead } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { leadsUrl, leadsupdateurls } from "../utils/network";

const { Option } = Select;

const AddLeadForm: FC<AddLeadFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingLead,
  onCloseWithoutEditing, // new prop

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  useEffect(() => {
    if (editingLead) {
      form.setFieldsValue(editingLead);
    } else {
      form.resetFields();
    }
  }, [editingLead, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingLead) {
        // Editing Lead
        response = await axios.put(`${leadsupdateurls}/${editingLead.id}`, values, headers);
      } else {
        // Adding new Lead
        response = await axios.post(leadsUrl , values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  const [isOther, setIsOther] = useState(false);

  const handleSourceChange = (value:any) => {
    if (value === 'other') {
      setIsOther(true);
      form.setFieldsValue({ source: '' });  // Clear the source field for other input
    } else {
      setIsOther(false);
      form.setFieldsValue({ source: value });  // Set the selected value
    }
  };

  const handleOtherInputChange = (e:any) => {
    form.setFieldsValue({ source: e.target.value });
  };
  const switchBackToDropdown = () => {
    setIsOther(false);
    form.setFieldsValue({ source: null });  // Clear the input field when switching back
  };

  return (
    <Drawer
      title={editingLead ? "Edit Lead" : "Add Lead"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the Lead name!' }]}
        >
          <Input placeholder="Lead Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input the Lead email!' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please input the Lead address!' }]}
        >
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input the Lead city!' }]}
        >
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: 'Please input the Lead state!' }]}
        >
          <Input placeholder="State" />
        </Form.Item>
        <Form.Item
          label="Zip"
          name="zip"
          rules={[{ required: true, message: 'Please input the Lead Zip code!' }]}
        >
          <Input placeholder="Zip" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: 'Please input the Lead phone number!' }]}
        >
          <Input placeholder="Phone" />
        </Form.Item>
        <Form.Item
        label="Source"
        name="source"
        rules={[{ required: true, message: 'Please select or input the Lead Source!' }]}
      >
        {!isOther ? (
          <Select
            placeholder="Select a source"
            onChange={handleSourceChange}
          >
          <Option value="google">Google</Option>
          <Option value="instagram">Instagram</Option>
          <Option value="referral">Referral</Option>
          <Option value="other">Other</Option>

          </Select>
        ) : (
          <Space>
            <Input
              placeholder="Please specify the source"
              onChange={handleOtherInputChange}
            />
            <Button onClick={switchBackToDropdown}>Back</Button>
          </Space>
        )}
      </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingLead ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddLeadForm;