import React, { Children, cloneElement, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Appointment, FranchiseeProps, salesProps } from "../utils/types";
import { useGetAppointments, useGetFranchisee, useGetsalescalendar } from "../utils/hooks";
import { Switch } from "@mui/material";
import AppointmentDetailsPopup from "../components/AppointmentDetailsPopup";
import { Drawer, Select, Tag } from "antd";
import { getAppointments, getsalescalendar } from "../utils/functions";
import AddAppointmentForm from "../components/AddAppointments";
import SalesOrderComponent from "../components/AddSalesComponent";
import { Modal, message } from 'antd';
const { Option } = Select;

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

function AppointmentsCalendar() {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [fetchingAppointments, setFetchingAppointments] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(true);
  const userRole = localStorage.getItem('token1');
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee

// State to manage the selection between viewing and adding a new appointment
const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null); // State to store selected date and time

  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };

  useGetAppointments(setAppointments, setFetchingAppointments, selectedFranchisee);
  const [salesAppointments, setSalesAppointments] = useState<salesProps[]>([]);
  const [fetchingSalesAppointments, setFetchingSalesAppointments] = useState<boolean>(true);
  useGetsalescalendar(setSalesAppointments, setFetchingSalesAppointments, selectedFranchisee);
  const [popupData, setPopupData] = useState<{ id: number, isSales: boolean } | null>(null); // State to store popup data
  const [viewSales, setViewSales] = useState<boolean>(false);

  useEffect(() => {
    getsalescalendar(setSalesAppointments, setFetching, selectedFranchisee);
    getAppointments(setAppointments, setFetching, selectedFranchisee);
  }, [selectedFranchisee]);

  const eventColorGetter = (event: any) => {
    return {
      style: {
        backgroundColor: event.employee?.color || "#3174ad",
      },
    };
  };

  const [popupData1, setPopupData1] = useState<{ appointment: salesProps | Appointment } | null>(null);
  
  const handleEventClick = (event: any) => {
    // Show a modal with two options: schedule new or view appointment
    Modal.confirm({
      title: "Appointment Action",
      content: "Do you want to view this appointment or schedule a new one?",
      okText: "Schedule New",
      cancelText: "View Appointment",
      onOk: () => {
        setSelectedDateTime(event.start); // Store the selected date and time
        // Check the mode: if in Estimates, show AddAppointmentForm; if in Sales, show SalesOrderComponent
        if (!viewSales) {
          setShowAddForm(true); // Show AddAppointmentForm when in Estimates mode
        } else {
          setShowSalesOrderDrawer(true); // Show SalesOrderDrawer when in Sales mode
        }
      },
      onCancel: () => {
        // View appointment logic remains unchanged
        const isSalesEvent = viewSales;
        const id = isSalesEvent ? event.customer?.id : event.lead?.id;
        const appointment = viewSales
          ? salesAppointments.find((app) => app.id === event.id)
          : appointments.find((app) => app.id === event.id);
        setPopupData({ id, isSales: isSalesEvent });
        if (appointment) {
          setPopupData1({ appointment });
        }
      }
    });
  };

  const events = viewSales ? salesAppointments.map(appointment => ({
    ...appointment,
    title: `${appointment.customer?.name} - ${new Date(appointment.expected_delivery_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
    start: new Date(appointment.expected_delivery_date),
    end: new Date(new Date(appointment.expected_delivery_date).getTime() + (30 * 60000)),
})) : appointments.map(appointment => ({
    ...appointment,
    title: `${appointment.lead?.name} - ${new Date(appointment.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
    start: new Date(appointment.date),
    end: new Date(new Date(appointment.date).getTime() + (30 * 60000))
}));
  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  const handleEstimatesClick = () => {
    setShowAddForm(true);
  };

  const handleFormClose = () => {
    setShowAddForm(false);
  };

  const handleFormSuccess = () => {
    setShowAddForm(false);
    getAppointments(setAppointments, setFetching, selectedFranchisee);
    setShowSalesOrderDrawer(false);
    getsalescalendar(setSalesAppointments, setFetching, selectedFranchisee);
  };

  const handleSaveSuccess = () => {
    getAppointments(setAppointments, setFetching, selectedFranchisee);
    getsalescalendar(setSalesAppointments, setFetching, selectedFranchisee);
  };

  const [showSalesOrderDrawer, setShowSalesOrderDrawer] = useState<boolean>(false);
  const handleInstallsClick = () => {
    setShowSalesOrderDrawer(true);
  };

  const handleSlotSelect = (slotInfo: any) => {
    // Show a modal to confirm scheduling a new appointment
    Modal.confirm({
      title: "Schedule New Appointment",
      content: `Do you want to schedule a new appointment on ${moment(slotInfo.start).format("YYYY-MM-DD HH:mm")}?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        setSelectedDateTime(slotInfo.start); // Store the selected time slot
        // Check the mode: if in Estimates, show AddAppointmentForm; if in Sales, show SalesOrderComponent
        if (!viewSales) {
          setShowAddForm(true); // Show AddAppointmentForm when in Estimates mode
        } else {
          setShowSalesOrderDrawer(true); // Show SalesOrderDrawer when in Sales mode
        }
      }
    });
  };
  const TouchCellWrapper: React.FC<{ children: React.ReactElement; value: Date }> = ({ children, value }) => {
    const child = Children.only(children); // This will throw an error if children is undefined

    return cloneElement(child, {
      onTouchEnd: () => handleSlotSelect({ start: value }),
      style: {
        cursor: "pointer", // Change cursor on hover
      },
    });
  };

      return (
    <>
      <div className="cardcalendar">
        <div className="cardHeader">
          <h2 className="headContent">Appointments</h2>
          <div>
            {userRole === 'Admin' && (
              <Select
                placeholder="Select Franchisee"
                style={{ width: 200, marginRight: 5 }}
                onChange={handleChangeFranchisee}
                value={selectedFranchisee}
              >
                {franchisee.map((fr) => (
                  <Option key={fr.id} value={fr.id}>
                    {fr.name}
                  </Option>
                ))}
              </Select>
            )}
            <Tag
              style={{
                cursor: "pointer",
                color: "#AB67CB",
              }}
              onClick={handleEstimatesClick}
            >
              Estimates
            </Tag>
            <Switch
              checked={viewSales}
              onChange={() => setViewSales(!viewSales)}
              inputProps={{ 'aria-label': 'Show Sales' }}
            />
            <Tag
              style={{
                cursor: "pointer",
                color: "#AB67CB",
              }}
              onClick={handleInstallsClick}
            >
              Installs
            </Tag>
          </div>
        </div>
        <br />
        <div>
          <Calendar
            views={["day", "agenda", "month"]}
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            onSelectEvent={handleEventClick}
            onSelectSlot={handleSlotSelect}
            events={events as any}
            style={{ height: "100vh" }}
            eventPropGetter={eventColorGetter}
            selectable
            components={{
              dateCellWrapper: (props) => (
                <TouchCellWrapper {...props} />
              ),
            }}
          />
        </div>
      </div>
      {popupData && (
        <AppointmentDetailsPopup
          isOpen={true}
          appointmentType={popupData.isSales ? "sales" : "visit"}
          appointmentId={popupData.id}
          appointment={popupData1?.appointment}
          onClose={() => setPopupData(null)}
          onSaveSuccess={handleSaveSuccess} // Pass the callback function here
        />
      )}
      {showAddForm && (
        <AddAppointmentForm
          isVisible={true}
          onSuccessCallBack={handleFormSuccess}
          onClose={handleFormClose}
          initialDateTime={selectedDateTime}
        />
      )}
      <Drawer
        title="Add Sales Order"
        placement="left"
        closable={true}
        onClose={() => setShowSalesOrderDrawer(false)}
        visible={showSalesOrderDrawer}
        width={1200}
      >
        <SalesOrderComponent onSuccessCallback={handleFormSuccess} selectedDate={selectedDateTime} />
      </Drawer>
    </>
  );
}

export default AppointmentsCalendar;