import React, { useEffect, useState } from "react";
import { Table, message, Popconfirm, Modal, Select, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AuthTokenType, FranchiseeProps, salesProps } from "../utils/types";
import { useGetFranchisee, useGetsales } from "../utils/hooks";
import { getAuthToken, getsales } from "../utils/functions";
import axios from "axios";
import { salesorderphotosUrl } from "../utils/network";
import SalesOrderPhotosDrawer from "../components/SalesOrderPhotoDrawer";
import { Camera, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import imageCompression from 'browser-image-compression';
const { Option } = Select;

const SalesOrderPhotos = () => {
  const userRole = localStorage.getItem('token1');

  const [fetching, setFetching] = useState(true);
  const [sales, setSales] = useState<salesProps[]>([]);
  const [fileLists, setFileLists] = useState<{ [key: number]: File[] }>({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState<number | null>(null);
  const [isCaptureModalOpen, setIsCaptureModalOpen] = useState(false);
  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };

  useGetsales(setSales, setFetching,selectedFranchisee);


  useEffect(() => {
    getsales(setSales, setFetching,selectedFranchisee);
        },[selectedFranchisee])



        const handleUploadImages = async (recordId: number, files: FileList | null) => {
          if (!files) return;
      
          const newFiles = Array.from(files);
          const compressedFiles = await Promise.all(newFiles.map(async (file) => {
            const options = {
              maxSizeMB: 1, // Maximum size in MB
              maxWidthOrHeight: 640, // Maximum width or height
              useWebWorker: true // Use web worker for compression
            };
      
            return await imageCompression(file, options);
          }));
      
          setFileLists(prevFileLists => ({
            ...prevFileLists,
            [recordId]: [...(prevFileLists[recordId] || []), ...compressedFiles],
          }));
      
          const fileNames = compressedFiles.map(file => file.name).join(", ");
          message.success(`Uploaded images for sales order ID ${recordId}: ${fileNames}`);
        };

  const handleDeleteImage = (recordId: number, file: File) => {
    const newFileList = fileLists[recordId].filter(f => f !== file);
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: newFileList
    }));
    message.success(`Deleted image ${file.name}`);
  };

  const handleSubmit = async (recordId: number) => {
    const headers = getAuthToken() as AuthTokenType;
    try {
      const formData = new FormData();
      formData.append("salesorder_id", recordId.toString());
      fileLists[recordId]?.forEach(file => {
        formData.append("files", file);
      });
      await axios.post(salesorderphotosUrl, formData, headers);
      message.success("Files submitted successfully");
    } catch (error) {
      console.error("Error submitting files:", error);
      message.error("Failed to submit files");
    }
  };

  const handleViewPhotos = (recordId: number) => {
    setSelectedSalesOrderId(recordId);
    setDrawerVisible(true);
  };

  const handleCapture = (dataUri: string, recordId: number) => {
    const byteString = atob(dataUri.split(',')[1]);
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const file = new File([ab], "camera_capture.jpeg", { type: mimeString });
    setFileLists(prevFileLists => ({
      ...prevFileLists,
      [recordId]: [...(prevFileLists[recordId] || []), file],
    }));
    setIsCaptureModalOpen(false);
  };

  const getColumnFilterValues = (data: salesProps[], key: keyof salesProps) => {
    const uniqueValues = new Set(data.map((item) => item[key]));
    return Array.from(uniqueValues);
};


  const salesOrderColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
      filters: getColumnFilterValues(sales, "customer_name").map((name) => ({ text: name, value: name })),
      onFilter: (value: string, record: salesProps) => record.customer_name === value,

    },
{
      title: "Actual Delivery Date",
      dataIndex: "actual_delivery_date",
      key: "actual_delivery_date",
    },
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee",
      filters: getColumnFilterValues(sales, "employee_name").map((name) => ({ text: name, value: name })),
      onFilter: (value: string, record: salesProps) => record.employee_name === value,

    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Uploaded Files",
      key: "uploaded_files",
      render: (text: any, record: salesProps) => (
        <div>
          {fileLists[record.id]?.map((file: File, index: number) => (
            <span key={index}>
              {file.name}
              <Popconfirm
                title="Are you sure to delete this file?"
                onConfirm={() => handleDeleteImage(record.id, file)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ marginLeft: 8 }} />
              </Popconfirm>
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: salesProps) => (
        <>
          <a
            onClick={(e) => document.getElementById(`file-upload-${record.id}`)?.click()}
          >
            Upload
          </a> |
          <input
            id={`file-upload-${record.id}`}
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={(e) => handleUploadImages(record.id, e.target.files)}
          />
          <a onClick={() => handleSubmit(record.id)}>Submit</a> |{' '}
          {' '}{' '}
          <a onClick={() => handleViewPhotos(record.id)}>View</a>
        </>
      ),
    },
  ];


  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = sales.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
     

  return (
    <>
      <div className="card">
        <div className="cardHeader">
          <h2 className="headContent">Sales Orders</h2>
          <div className="rightContent">

          <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px' }}
            />
 {userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
 )}
</div>

        </div>
        <br />
        <Table
          className="custom-table"
          dataSource={filteredData}
          columns={salesOrderColumns as any}
          size="small"
          loading={fetching}
        />
      </div>
      <SalesOrderPhotosDrawer
        salesOrderId={selectedSalesOrderId}
        visible={drawerVisible}
        onClose={() => {
          setSelectedSalesOrderId(null);
          setDrawerVisible(false);
        }}
      />
    </>
  );
};

export default SalesOrderPhotos;