import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Switch, Select, DatePicker, Modal } from "antd";
import { AuthTokenType, DataProps, AddAppointmentFormProps, Appointment, lead, location, UserProps } from "../utils/types";
import { getAppointments, getAuthToken, getLeadestimates, } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { useGetAppointments, useGetLead, useGetLeadestimates, useGetLocation, useGetUsers } from "../utils/hooks";
import { appointmentsUrl, appointmentsupdateurls } from "../utils/network";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import utc plugin
import AppointmentsCalendar from "../pages/AppointmentsCalendar";
import AddLeadForm from "./AddLeadForm";

dayjs.extend(utc); // Enable utc plugin

const AddAppointmentForm: FC<AddAppointmentFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  onCloseWithoutEditing, // new prop
  editingAppointment,
  initialDateTime, // Receiving the selected date and time  
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [fetching, setFetching] = useState<boolean>(true);




  const [lead, setlead] = useState<lead[]>([]);
  useGetLeadestimates(setlead, setFetching);
  const [locations, setLocations] = useState<location[]>([]);
  useGetLocation(setLocations, setFetching);
  const [calendarVisible, setCalendarVisible] = useState<boolean>(false); // State to control modal visibility  
  const [users, setUsers] = useState<UserProps[] | undefined>();

  useGetUsers(setUsers, setFetching);

  useEffect(() => {
    if (editingAppointment) {
      form.setFieldsValue({
        ...editingAppointment,
        lead_id: editingAppointment.lead?.id,
        from_location_id: editingAppointment.from_location?.id,
        employee_id: editingAppointment.employee?.id,
        date: editingAppointment.date ? dayjs(editingAppointment.date) : null,
      });
    } else {
      // If not editing, set the selected date from the calendar click
      form.setFieldsValue({
        date: initialDateTime ? dayjs(initialDateTime) : null,
      });
    }
  }, [editingAppointment, form, initialDateTime]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

 
    try {
      let response: AxiosResponse;

      if (editingAppointment) {
        response = await axios.patch(`${appointmentsupdateurls}/${editingAppointment.id}`, values, headers);
      } else {
        response = await axios.post(appointmentsUrl, values, headers);
      }

      setLoading(false);

      if (response) {
        form.resetFields()
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };
  const [addLeadVisible, setAddLeadVisible] = useState<boolean>(false);
  const handleLeadSelection = (value: any) => {
    form.setFieldsValue({ lead_id: value }); // Set lead_id in form when lead is selected
  };

  return (
    <>

    <Drawer
      title={editingAppointment ? "Edit Appointment" : "Add Appointment"}
      visible={isVisible}
      placement="left"
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >

            <Form layout="vertical" onFinish={onSubmit} form={form} onValuesChange={() => setHasChanges(true)}>
            <Form.Item label="Location" name="from_location_id">
         <Select>
            <Select.Option value="" disabled>Select Location</Select.Option>
            {locations.map(location => (
              <Select.Option key={location.id} value={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Lead"
          name="lead_id"
          rules={[{ required: false, message: 'Please select the Lead!' }]}
        >
                <div style={{ display: 'flex', alignItems: 'center' }}>

          <Select onChange={handleLeadSelection}>
            <Select.Option value="" disabled>Select a Lead</Select.Option>
            {lead.map(lead => (
              <Select.Option key={lead.id} value={lead.id}>
                {lead.name}
              </Select.Option>
            ))}
          </Select>
          <Button type="primary" onClick={() => setAddLeadVisible(true)} style={{ marginLeft: 8 }}>                Add Lead
              </Button>
              </div>
        </Form.Item>
        <Form.Item
          label="Employee"
          name="employee_id"
          rules={[{ required: false, message: 'Please select an Employee!' }]}
        >
          <Select>
            <Select.Option value="" disabled>Select an Employee</Select.Option>
            {users?.map(lead => (
              <Select.Option key={lead.id} value={lead.id}>
                {lead.fullname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>


        <Form.Item
        label={("Appointment Date")}
        name="date"
        rules={[{ required: true, message: ('Please input the Date!') }]}
      >
          <DatePicker
          showTime
          format="YYYY-MM-DD HH:mm"
          onChange={(date) => form.setFieldsValue({ date: date ? dayjs(date) : null })} />

      </Form.Item>


        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingAppointment ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        
        visible={calendarVisible}
        onCancel={() => setCalendarVisible(false)}
        footer={null}
        width={700}
       
      >
        <AppointmentsCalendar /> {/* Your calendar component */}
      </Modal>
    </Drawer>
        <AddLeadForm
          isVisible={addLeadVisible}
          onSuccessCallBack={() => {
            getLeadestimates(setlead,setFetching)
            setAddLeadVisible(false); // Close AddLeadForm drawer after successful submission
          }}
          onClose={() => setAddLeadVisible(false)}
        />

        </>
  );
};

export default AddAppointmentForm;