import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Button, Slider, Tooltip, Upload } from 'antd';
import CreateIcon from '@mui/icons-material/Create';
import { BsEraserFill } from "react-icons/bs";
import { 
  LineStyle as PenIcon, 
  FormatPaint as DottedLineIcon, 
  Highlight as EraserIcon, 
  SelectAll as SelectIcon, 
  Clear as ClearSelectionIcon, 
  ZoomIn as ZoomInIcon, 
  ZoomOut as ZoomOutIcon, 
  Undo as UndoIcon, 
  Redo as RedoIcon, 
  Upload as UploadIcon, 
  Delete as DeleteBackgroundImageIcon, 
  Clear as ClearAllIcon, 
  Save as SaveIcon 
} from '@mui/icons-material'; // Ensure you have @mui/icons-material installed

export interface ToolOption {
  textSize: number;
  textColor: string;
  defaultText: string | { id: string };
  [key: string]: any; // To allow any additional properties
}
interface DrawingCanvasProps {
  onSave: (dataUrl: string) => void;
  initialImage?: string | null; // Allow initial image to be optional
}


const DrawingCanvas: React.FC<DrawingCanvasProps> = ({ onSave, initialImage }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [tool, setTool] = useState<string>('pen');
  const [penColor, setPenColor] = useState<string>('#000000');
  const [penSize, setPenSize] = useState<number>(5);
  const [eraserSize, setEraserSize] = useState<number>(10);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [startX, setStartX] = useState<number | null>(null);
  const [startY, setStartY] = useState<number | null>(null);
  const [history, setHistory] = useState<string[]>([]);
  const [redoHistory, setRedoHistory] = useState<string[]>([]);
  const [zoom, setZoom] = useState<number>(1);
  const [selectStart, setSelectStart] = useState<{ x: number; y: number } | null>(null);
  const [selectionRect, setSelectionRect] = useState<{ x: number; y: number; width: number; height: number } | null>(null);



  
  let lastTap = 0;

  useEffect(() => {
    if (initialImage) {
      // Add the appropriate prefix if it's a base64 string
      const base64Prefix = initialImage.startsWith('data:image/')
        ? ''
        : 'data:image/png;base64,';
  
      setBackgroundImage(`${base64Prefix}${initialImage}`); // Format the Base64 string as a URL
    }
  }, [initialImage]);

  const handleDoubleTap = (event: TouchEvent) => {
    const now = Date.now();
    const timeSinceLastTap = now - lastTap;

    if (timeSinceLastTap < 300 && timeSinceLastTap > 0) {
      event.preventDefault();
      event.stopPropagation();
    }

    lastTap = now;
  };


  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener('touchend', handleDoubleTap);

      return () => {
        canvas.removeEventListener('touchend', handleDoubleTap);
      };
    }
  }, []);
  

  const saveCanvasState = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const dataUrl = canvas.toDataURL();
      return dataUrl;
    }
    return null;
  };

  const restoreCanvasState = (dataUrl: string | null) => {
    const canvas = canvasRef.current;
    if (canvas && dataUrl) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          if (backgroundImage) {
            const bgImg = new Image();
            bgImg.src = backgroundImage;
            bgImg.onload = () => {
              ctx.drawImage(bgImg, 0, 0, canvas.width, canvas.height);
            };
          }
        };
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const savedState = saveCanvasState(); // Save the current canvas state
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        handleClear(); // Clear canvas and reapply background
        restoreCanvasState(savedState); // Restore the saved canvas state
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial setup

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [backgroundImage]);


  useEffect(() => {
    handleClear(); // Ensure canvas is cleared on mount or background image change
  }, [backgroundImage]);

  const saveHistory = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const dataUrl = canvas.toDataURL('image/png');
      setHistory((prevHistory) => [...prevHistory, dataUrl]);
      setRedoHistory([]); // Clear redo history on new action
    }
  };

  const startDrawing = (x: number, y: number) => {
    setIsDrawing(true);
    saveHistory(); // Save the current state before starting a new drawing
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.strokeStyle = tool === 'eraser' ? '#FFFFFF' : penColor;
        ctx.lineWidth = tool === 'eraser' ? eraserSize : penSize;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'bevel'
        ctx.setLineDash(tool === 'dotted' ? [5, 15] : []); // Dotted line
        ctx.beginPath();
        console.log(x,y)
        ctx.moveTo(x, y);
        setStartX(x);
        setStartY(y);
        if (tool === 'select') {

          setSelectStart({ x, y });
          setSelectionRect(null); // Reset selection rectangle
        }
      }
    }
  };

  const draw = (x: number, y: number) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        if (tool === 'pen' || tool === 'eraser' || tool === 'dotted') {
          ctx.lineTo(x, y);
          ctx.stroke();
        } else if (tool === 'select' && selectStart) {
          const rect = canvas.getBoundingClientRect();
          
          const scaleX = canvas.width / rect.width;
          const scaleY = canvas.height / rect.height;
          const rectWidth = x - (selectStart.x)/scaleX;
          const rectHeight = y - selectStart.y/scaleY;

          setSelectionRect({ x: selectStart.x/scaleX, y: selectStart.y/scaleY, width: rectWidth, height: rectHeight });
     
        }
      }
    }
  };

//  const stopDrawing = () => {
//    if (tool === 'select' && selectionRect) {
//      // Keep the selection rectangle visible until cleared
//    }
    
//    setIsDrawing(false);
//  };

const stopDrawing = () => {
  if (tool === 'select' && selectionRect) {
    // Keep the selection rectangle visible until cleared
  }

  // Reset the context state for a new stroke
  const canvas = canvasRef.current;
  if (canvas) {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.closePath(); // Ensure the path is closed
      if (tool === 'pen' || tool === 'eraser' || tool === 'dotted') {
        ctx.beginPath(); // Start a new path for the next stroke
      }
    }
  }
  
  setIsDrawing(false);
};

  const handleClear = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if (backgroundImage) {
          const img = new Image();
          img.src = backgroundImage;
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
        }
      }
    }
  };

  const saveImage = () => {
    if (canvasRef.current) {
      const dataUrl = canvasRef.current.toDataURL(); // Get data URL
      onSave(dataUrl); // Pass data URL to parent component
    }
  };



  const handleImageUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setBackgroundImage(reader.result as string);
      handleClear(); // Reapply background after upload
    };
    reader.readAsDataURL(file);
  };


  const uploadProps = {
    beforeUpload: (file: File) => {
      handleImageUpload(file);
      return false; // Prevent auto-upload
    },
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    const rect = canvasRef.current?.getBoundingClientRect();
    const canvas = canvasRef.current;
    if (rect && canvas) {
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const x = (e.touches[0].clientX - rect.left) * scaleX;
      const y = (e.touches[0].clientY - rect.top) * scaleY;
      startDrawing(x, y);
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    const rect = canvasRef.current?.getBoundingClientRect();
    const canvas = canvasRef.current;
    if (rect && canvas) {
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const x = (e.touches[0].clientX - rect.left) * scaleX;
      const y = (e.touches[0].clientY - rect.top) * scaleY;
      draw(x, y);
    }
  };

  const undo = () => {
    // Save the current state to the redo stack
    const currentState = canvasRef.current?.toDataURL() || '';
    setRedoHistory([currentState, ...redoHistory]);
  
    // Retrieve the most recent state from the history stack
    setHistory(prevHistory => {
      const history = [...prevHistory];
      const lastState = history.pop();
      
      if (lastState) {
        // Apply the last state from the updated history stack
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          const img = new Image();
          img.src = lastState;
          img.onload = () => {
            ctx?.clearRect(0, 0, canvas.width, canvas.height);
            if (backgroundImage) {
              const bgImg = new Image();
              bgImg.src = backgroundImage;
              bgImg.onload = () => {
                ctx?.drawImage(bgImg, 0, 0, canvas.width, canvas.height);
                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
              };
            } else {
              ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
            }
          };
        }
      }
  
      return history;
    });
  };

  const redo = () => {
    // Save the current state to the history stack
    const currentState = canvasRef.current?.toDataURL() || '';
    setHistory(prevHistory => [currentState, ...prevHistory]);
  
    // Retrieve the next state from the redo stack
    setRedoHistory(prevRedoHistory => {
      const redoHistory = [...prevRedoHistory];
      const nextState = redoHistory.shift();
  
      if (nextState) {
        // Apply the next state from the updated redo stack
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          const img = new Image();
          img.src = nextState;
          img.onload = () => {
            ctx?.clearRect(0, 0, canvas.width, canvas.height);
            if (backgroundImage) {
              const bgImg = new Image();
              bgImg.src = backgroundImage;
              bgImg.onload = () => {
                ctx?.drawImage(bgImg, 0, 0, canvas.width, canvas.height);
                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
              };
            } else {
              ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
            }
          };
        }
      }
  
      return redoHistory;
    });
  };
  
  const zoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level 3x
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const newWidth = canvas.width * 1.1;
      const newHeight = canvas.height * 1.1;
      const img = new Image();
      img.src = canvas.toDataURL();
      img.onload = () => {
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);
      };
    }
  };

  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1)); // Min zoom level 0.1x
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const newWidth = canvas.width / 1.1;
      const newHeight = canvas.height / 1.1;
      const img = new Image();
      img.src = canvas.toDataURL();
      img.onload = () => {
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);
      };
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLCanvasElement>) => {
    if (e.ctrlKey) {
      if (e.key === 'z') {
        undo();
      } else if (e.key === 'y') {
        redo();
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;
      startDrawing(x, y);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;
      draw(x, y);
    }
  };

  const handleMouseUp = () => {
    
    stopDrawing();
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    stopDrawing(); // Call the function to stop drawing
  };

  
  const clearSelection = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        if (selectionRect) {
          const rect = canvas.getBoundingClientRect();
          const scaleX = canvas.width / rect.width;
          const scaleY = canvas.height / rect.height;

          ctx.clearRect(
            selectionRect.x*scaleX,
            selectionRect.y*scaleY,
            selectionRect.width*scaleX,
            selectionRect.height*scaleY
          );
          setSelectionRect(null); // Remove the rectangle after clearing
        }
      }
    }
  };
  const deleteBackgroundImage = () => {
    setBackgroundImage(null);
    handleClear(); // Clear canvas after deleting the background
    setBackgroundImage(null);
    handleClear(); // Clear canvas after deleting the background
  };

  
 

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <canvas
        ref={canvasRef}
        style={{
          border: '1px solid #000',
          width: '100%',
          height: '100vh',
          touchAction: 'none',
          transform: `scale(${zoom})`,
          transformOrigin: '0 0',
        }}
                onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onKeyDown={handleKeyDown}
        tabIndex={0} // To allow keyboard events
      />

      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        zIndex: 10, 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        padding: '10px',
        background: '#f0f0f0',
        borderBottom: '1px solid #ddd',
        width: '100%',
        boxSizing: 'border-box'
      }}>
       
        <Tooltip title="Pen Tool">
          <Button onClick={() => setTool('pen')} icon={<CreateIcon />} style={{ color: tool === 'pen' ? 'blue' : 'inherit',margin: '0 5px' }}
 />
        </Tooltip>
        <Tooltip title="Dotted Line Tool">
          <Button onClick={() => setTool('dotted')} icon={<PenIcon />} style={{ color: tool === 'dotted' ? 'blue' : 'inherit',margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Eraser Tool">
          <Button onClick={() => setTool('eraser')} icon={<BsEraserFill />} style={{ color: tool === 'eraser' ? 'blue' : 'inherit',margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Select Tool">
          <Button onClick={() => setTool('select')} icon={<SelectIcon />} style={{ color: tool === 'select' ? 'blue' : 'inherit',margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Clear Selection">
          <Button onClick={clearSelection} icon={<ClearSelectionIcon />} style={{ margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Zoom In">
          <Button onClick={zoomIn} icon={<ZoomInIcon />} style={{ margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Zoom Out">
          <Button onClick={zoomOut} icon={<ZoomOutIcon />} style={{ margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Undo">
          <Button onClick={undo} icon={<UndoIcon />} style={{ margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Redo">
          <Button onClick={redo} icon={<RedoIcon />} style={{ margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Upload Image">
          <Upload {...uploadProps} showUploadList={false} style={{ margin: '0 5px' }}>
            <Button icon={<UploadIcon />} />
          </Upload>
        </Tooltip>
        <Tooltip title="Delete Background Image">
          <Button icon={<DeleteBackgroundImageIcon />} onClick={deleteBackgroundImage} style={{ margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Clear All">
          <Button onClick={handleClear} icon={<ClearAllIcon />} style={{ margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Save Image">
          <Button onClick={saveImage} icon={<SaveIcon />} style={{ margin: '0 5px' }} />
        </Tooltip>
        <Tooltip title="Pen Size">
          <Slider
            min={1}
            max={20}
            value={penSize}
            onChange={(value) => setPenSize(value as number)}
            style={{ width: 150, margin: '0 10px' }}
            tooltip={{ formatter: (value) => `Pen Size: ${value}` }}
          />
        </Tooltip>
        <Tooltip title="Eraser Size">
          <Slider
            min={1}
            max={50}
            value={eraserSize}
            onChange={(value) => setEraserSize(value as number)}
            style={{ width: 150, margin: '0 10px' }}
            tooltip={{ formatter: (value) => `Eraser Size: ${value}` }}
          />
        </Tooltip>
        <Tooltip title="Color">
        <input type="color" value={penColor} onChange={(e) => setPenColor(e.target.value)} />
        </Tooltip>
        {selectionRect && (
          <div
            style={{
              position: 'absolute',
              border: '1px dashed #000',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              left: selectionRect.x,
              top: selectionRect.y,
              width: selectionRect.width,
              height: selectionRect.height,
              zIndex: 20,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DrawingCanvas;