import React, { useEffect, useState, ChangeEvent } from "react";
import { getAuthToken } from "../utils/functions";
import { ForkRight } from "@mui/icons-material";
import {
  AddRemovesalesItemProps,
  AuthTokenType,
  customer,
  location,
  product,
  salesProps,
} from "../utils/types";
import { useGetProduct, useGetLocation, useGetCustomer, useGetCustomersales } from "../utils/hooks";
import { Button, Form, Input, Table, notification, Select, Modal } from "antd";
import axios, { AxiosResponse } from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { salesorderUrl } from "../utils/network";
import AppointmentsCalendar from "./AppointmentsCalendar";

const { Option } = Select;

const formatProductProps = (
  product: product[],
  onAddItem: (productdata: product) => void,
  onChangeQty: (value: number, product_id: number) => void
) => {
  return product.map((item) => ({
    ...item,
    key: item.id,
    action: (
      <div>
        <Input
          style={{ width: "50px" }}
          type="number"
          min={1}
          defaultValue={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeQty(parseInt(e.target.value), item.id as any)
          }
        />
        <Button
          style={{ width: "50px" }}
          onClick={() => onAddItem(item)}
        >
          +
        </Button>
      </div>
    ),
  }));
};

const formatsalesDataProps = (
  salesdata: salesProps[],
  onRemoveItem: (productid: number) => void,
  onChangeQty: (value: number, product_id: number) => void
) => {
  return salesdata.map((item) => ({
    ...item,
    key: item.id,
    action: (
      <div>
        <Input
          style={{ width: "50px" }}
          type="number"
          min={1}
          max={item.quantity}
          defaultValue={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeQty(parseInt(e.target.value), item.id)
          }
        />
        <Button
          style={{ width: "50px" }}
          onClick={() => onRemoveItem(item.id)}
        >
          -
        </Button>
      </div>
    ),
  }));
};


interface salesFormProps {
  salesData?: salesProps; // Optional prop to receive estimate data for editing
  onUpdate?: () => void;
}

const SalesOrder: React.FC<salesFormProps> = ({ salesData,onUpdate }) => {
  const [fetching, setFetching] = useState(true);
  const [product, setProduct] = useState<product[]>([]);
  const [salesdata, setsalesData] = useState<salesProps[]>([]);
  const [salesitemqty, setsalesItemQty] =
    useState<AddRemovesalesItemProps>({});
  const [salesitemqdataty, setsalesItemDataQty] =
    useState<{ [key: number]: number }>({});
    const [form] = Form.useForm();

  const [fromlocation, setFromLocation] = useState<location[]>([]);
  const [fromlocationselected, setFromLocationSelected] = useState("");
  const [loading, setLoading] = useState(false);

  const [edd, Setedd] = useState<Date | null>(new Date());
  const [calendarVisible, setCalendarVisible] = useState<boolean>(false); // State to control modal visibility


  useEffect(() => {
    
    if (salesData) {
      // Map through sales order items to create materials
      const materials = salesData.salesorder_items?.map((item) => ({
        key: item.item.id,
        id: item.item.id,
        item: item.item.name,
        amount: item.item.sku_price*item.quantity,
        quantity: item.quantity,
        price:item.item.sku_price
      })) || [];
  
      // Update the state with the materials
      setsalesData(materials);
  
      // Set form values based on salesData
      form.setFieldsValue({
        from_location_id: salesData.from_location?.id,
        to_customer_id: salesData.customer?.id,
        expected_delivery_date: salesData.expected_delivery_date,
        mode: salesData.mode,
      });
  
      // Update selected locations
      setFromLocationSelected(salesData.from_location?.id ?? "");
      setToCustomerSelected(salesData.customer?.id?.toString() ?? "");
    }
  }, [salesData]);





  const productcolumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={("Search code")}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<ForkRight />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {("Search")}
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm({ closeDropdown: false }); // This will reset the filters and trigger a table update
            }}
            size="small"
            style={{ width: 90 }}
          >
            {("Reset")}
          </Button>
        </div>
      ),
      filterIcon: (filtered:boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value:any, record:any) => record.code.toString().toLowerCase().includes(value.toLowerCase()),

    },
    {
      title: "Price",
      dataIndex: "sku_price",
      key: "sku_price",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
    },
  ];

  const salesordercolumns = [
    {
      title: "Name",
      dataIndex: "item",
      key: "item",
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
    },
  ];

  useGetProduct(setProduct, setFetching);
  const [tocustomer,setToCustomer]=useState<customer[]>([])
  const [tocustomerselected,setToCustomerSelected]=useState("")
  useGetCustomersales(setToCustomer,setFetching)


  const additemtosalesdata = (productdata: product) => {
    const qty = salesitemqty[productdata.id as any] || 1;

    let _salesdata: salesProps[] = [];

    const id = salesdata.filter(
      (id) => id.id === (productdata.id as any)
    );
    if (id.length > 0) {
      _salesdata = salesdata.map((id) => {
        if (id.id === (productdata.id as any)) {
          const _qty = id.quantity + qty;
          return {
            ...id,
            quantity: _qty,
            amount:productdata.sku_price*(_qty),

          };
        }
        return id;
      });
    } else {
      const tempsalesdata: salesProps = {
        key: productdata.id as any,
        item: productdata.name as any,

        id: productdata.id as any,
        quantity: qty,
        amount:productdata.sku_price*qty,
        price:productdata.sku_price,

      };

      _salesdata = [...salesdata, tempsalesdata];
    }
    setsalesData(_salesdata);
  };

  const removeitemfromsalesdata = (productid: number) => {
    const qty = salesitemqdataty[productid] || 1;
    let _salesdata: salesProps[] = [];
    const item = salesdata.filter((item) => item.id === productid)[0];

    if (qty >= item.quantity) {
      _salesdata = salesdata.filter((item) => item.id !== productid);
    } else {
      _salesdata = salesdata.map((item) => {
        if (item.id === productid) {
          return {
            ...item,
            quantity: item.quantity - qty,
            amount: (item.price ? item.price : 0) * (item.quantity - qty)
          };
        }
        return item;
      });
    }
    setsalesData(_salesdata);
  };

  const changesalesqty = (value: number, product_id: number) => {
    setsalesItemQty({
      ...salesitemqty,
      [product_id]: value,
    });
  };

  const changesalesremoveqty = (value: number, product_id: number) => {
    setsalesItemDataQty({
      ...salesitemqty,
      [product_id]: value,
    });
  };

  const clearsalesOrderData = () => {
    setsalesData([]);
    setsalesItemDataQty([]);
    form.resetFields();
  };

  useGetLocation(setFromLocation, setFetching);

  const submitsto = async () => {
    if (salesdata.length < 1) {
      notification.error({
        message: "Add Items to Order",
      });
    } else {
      const totalAmount = salesdata.reduce((acc, item) => {
        if (item.amount !== undefined) {
          return acc + item.amount;
        }
        return acc;
      }, 0);   
      const values = await form.validateFields();
      const datatosend = {
        from_location_id: fromlocationselected,
        expected_delivery_date: edd,
        actual_delivery_date: edd,
        customer_id : tocustomerselected, 
        status: "Open",
        mode:values.mode,
        amount:totalAmount,
        pending:totalAmount,
        salesorder_items_data: salesdata.map((item) => ({
          item_id: item.key,
          quantity: item.quantity,
          amount:item.amount
        })),
      };

      setLoading(true);
      const headers = getAuthToken() as AuthTokenType;
      try {
        const headers = getAuthToken() as AuthTokenType;
        const response: AxiosResponse = salesData 
        ? await axios.put(`${salesorderUrl}/${salesData?.id}`, datatosend, headers) // PATCH request
        : await axios.post(salesorderUrl, datatosend, headers); // POST request
  
    
        notification.success({
          message: "Operation Success",
          description: "Order Created Successfully",
        });
        form.resetFields();
        if (onUpdate) {
          onUpdate();
      }
      } catch (e) {
        notification.error({
          message: "Operation Error",
        });
        form.resetFields();
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="ordercreation">
      <div className="cardcart1">
        <div className="cardHeader">
          <h2 className="headContent">Products</h2>
        
        </div>
        <br/>
        <Table
          className="custom-table"
          size="small"
          dataSource={formatProductProps(
            product,
            additemtosalesdata,
            changesalesqty
          )}
          columns={productcolumns}
        />
      </div>
      <br />

      <div className="cardcart2">
        <div className="cardHeader">
          <h2 className="headContent">Sales Order</h2>
        </div>
        <br/>
        <Table
          className="custom-table"
          size="small"
          dataSource={formatsalesDataProps(
            salesdata,
            removeitemfromsalesdata,
            changesalesremoveqty
          )}
          columns={salesordercolumns}
        />
        <Form layout="vertical" form={form}>
          <br/>
          <Form.Item  name="from_location_id">
            <Select
              defaultValue=""
              onChange={(value) => setFromLocationSelected(value)}
            >
              <Option value="">Serving Location</Option>
              {fromlocation.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item 
          name="to_customer_id"
                      >
          <Select defaultValue="" onChange={value => setToCustomerSelected(value)}>
                  <Option value="" >Customer</Option>
                  {
                          tocustomer.map((item,index)=><Option value={item.id}key={index}>{item.name}</Option>)
                  }
                  
              </Select>
          </Form.Item>
          <Form.Item label="Expected Delivery Date" name="expected_delivery_date">
            <DatePicker
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="yyyy-MM-dd HH:mm"
             selected={edd} onChange={(date) => Setedd(date)} />
          </Form.Item>
          <Form.Item label="Mode of Payment" name="mode">
  <Select>
    <Select.Option value="cash">Cash</Select.Option>
    <Select.Option value="check">Check</Select.Option>
    <Select.Option value="credit_card">Credit Card</Select.Option>
    <Select.Option value="bank_transfer">Bank Transfer</Select.Option>
    <Select.Option value="paypal">PayPal</Select.Option>
    <Select.Option value="zelle">Zelle</Select.Option>
    <Select.Option value="venmo">Venmo</Select.Option>
  </Select>
</Form.Item>

        </Form>
        <Button type="primary" onClick={submitsto}>
          Create Order
        </Button>
        <Button danger onClick={clearsalesOrderData}>
          Clear
        </Button>
      </div>
      <Modal
        
        visible={calendarVisible}
        onCancel={() => setCalendarVisible(false)}
        footer={null}
        width={700}
       
      >
        <AppointmentsCalendar /> {/* Your calendar component */}
      </Modal>

    </div>
  );
}

export default SalesOrder;